@charset "utf-8";

/*---------------------------------
	bit Common CSS 2018.2
	CSS inoue Document 2018.02
	Last Update 2018.02.00 inoue

・共通設定
・枠設定
・ヘッダ
・グローバルナビ
・コンテンツ部
・サイドナビ
・フッタ
---------------------------------*/

@import url('https://fonts.googleapis.com/css?family=Open+Sans');

/*####################　共通設定　####################*/
*{
  box-sizing:border-box;
}

html {
  overflow-y: scroll;
  margin: 0;
  padding: 0;
  height: 100%;
  clear: both;
}

body {
  line-height: 1.6;
  text-align: center;
  font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
  /* font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3","Hiragino Mincho Pro", "ＭＳ 明朝", serif; */
  /* font-family: helvetica,'arial black',arial,sans-serif; */
  font-feature-settings: "palt";
  -moz-font-feature-settings: "palt";
  -webkit-font-feature-settings: "palt";
  letter-spacing: 0.045em;
  -webkit-text-size-adjust: 100%;
  font-size: 16px;
  font-weight: 500;
  /*font-weight: 500;  ※游明朝の時は400*/
  color: #000;
}

p {
  text-align: justify;
  text-justify: inter-ideograph;
}

a {
  color: #000;
  text-decoration: 　underline;
  &:hover {
    text-decoration: none;
  }
  &:visited {
    color: #000;
  }
  &:link {
    color: #000;
  }
}

hr, .showBox {
  display: none;
}

#Header, #GlobalNav, #Container {
  margin: 0 auto;
  text-align: left;
}

#Footer {
  margin: 0 auto;
  text-align: left;
}

#Container:after, .contBox:after {
  content: "";
  display: block;
  clear: both;
}

/*　パンくず　※ブラウザ幅からはみ出たら...が出るタイプ
pc,sp共部分
-------------------------------------*/
#TopicPath {
  margin-top: 20px;
  margin-right: auto;
  margin-bottom: 106px;
  margin-left: 0px;
  min-width: 1000px;
  text-align: left;
  ol {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-text-overflow: ellipsis;
    white-space: nowrap;
    list-style: none;
    line-height: 1;
    color: #bdbdbd;
    &:after {
      content: "";
      display: block;
      clear: both;
    }
    li {
      display: inline;
      margin-right: 2px;
      padding-left: 12px;
      background: url(../img/contents/arrow_topic.png) no-repeat left center;
      font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
      font-weight: 500;
      font-size: 12px;
      color: #bdbdbd;
      &.home {
        padding-left: 0px;
        background: none;
      }
      &:last-of-type {
        margin-right: 0;
      }
      a {
        text-decoration: underline;
        color: #666666;
        &:hover {
          text-decoration: none;
        }
        &:visited {
        color: #666666;
        }
      }
    }
  }
}

/* アコーディオン用css */
/*#################### PC ####################*/

  body {
    min-width: 1000px;
  }
  p {
    margin: 0 0 10px;
    line-height: 1.75;
    font-size: 16px;
  }
  a {
    &[href^="tel"] {
      text-decoration: none;
      &:hover {
        cursor: text;
      }
    }
    &:hover {
      text-decoration: none;
    }
  }
  .click {
    cursor: pointer;
  }
  .pc_display,.disp_pc {
    display: block;
  }
  .sp_display, .sp_display_inline,.disp_sp {
    display: none;
  }
  /*####################　枠設定　####################*/
  #Wall {
    height: 162px;
  }
  #Header, #GlobalNav, #Container {
    min-width: 1000px;
  }
  .innerBasic{
    width:1000px;
    margin:0 auto;
  }
  .innerBox {
    min-width: 1000px;
    margin: 0 auto;
  }
  #Footer {
    min-width: 1000px;
  }
  /*####################　ヘッダ　####################*/
  #Header{
    position: fixed;
    top:0;
    height: 162px;
    background-color: #fff;
    width: 100%;
    min-width: 1000px;
    z-index: 9990;
    h1{
      padding-top: 30px;
      a{
        display: block;
        background-repeat: no-repeat;
        background-position: 0px 0px;
        background-image: url(../img/header/logo.png);
        width: 410px;
        height: 46px;
        background-size: 410px auto;
        text-indent: -9999px;
      }
    }
    #ContBoxHeader{
      position: relative;
      min-width: 1000px;
    }
    #HeaderMenu {
      position: absolute;
      top: 30px;
      right: 0;
      ul{
        li {
          display: inline-block;
          margin-left: 20px;
          text-align: right;
          font-size: 12px;
          vertical-align: middle;
          font-weight: bold;
          color: #072863;
          a {
            display: block;
            width: 180px;
            height: 46px;
            background-color: #072863;
            text-align: center;
            text-decoration: none;
            font-size: 15px;
            font-weight: 400;
            line-height: 46px;
            color: #fff;
            &:hover {
              opacity: 0.7;
            }
          }
        }
      }
    }
  }



  /*####################　グローバルナビ　####################*/
  #GlobalNav{
    position: fixed;
    top:108px;
    margin-bottom: 25px;
    background-color: #fff;
    width: 100%;
    min-width: 1000px;
    z-index: 9999;
    ul{
      font-size: 0;
      li{
        position:relative;
        display: inline-block;
        height: 24px;
        font-size: 16px;
        line-height: 24px;
        &:before {
          content: "";
          width:2px;
          border-right: 2px solid #f3f3f3;
          position: absolute;
          right: 0px;
          top: 4px;
          bottom: 4px;        }
        &:nth-of-type(1){
          width: 13%;
        }
        &:nth-of-type(2){
          width: 12.2%;
        }
        &:nth-of-type(3){
          width: 18%;
        }
        &:nth-of-type(4){
          width: 13.3%;
        }
        &:nth-of-type(5){
          width: 14.2%;
        }
        &:nth-of-type(6){
          width: 13.3%;
        }
        &:nth-of-type(7){
          width: 15.5%;
          &:before{
            content: none;
          }
        }
        a{
          display: block;
          width: 100%;
          height: 24px;
          text-align: center;
          text-decoration: none;
          &:hover{
            opacity: 0.7;
          }
        }
      }
    }
  }
  #PageClinical #GlobalNav ul li:nth-of-type(1) a,
  #PageInvitro #GlobalNav ul li:nth-of-type(2) a,
  #PageClinics #GlobalNav ul li:nth-of-type(3) a,
  #PageRecord #GlobalNav ul li:nth-of-type(4) a,
  #PageCosmetics #GlobalNav ul li:nth-of-type(5) a,
  #PageCompany #GlobalNav ul li:nth-of-type(6) a,
  #PageFaq #GlobalNav ul li:nth-of-type(7) a { color: #bdbdbd; }

  #MenuButton {
    display: none !important;
  }
  /*####################　サブメニュー　####################*/
  #Sub {}
  /*####################　コンテンツ部　####################*/

  /*　メインイメージ
  -------------------------------------*/
  #MainImg {
  height: 180px;
  background-color: #122953;
  font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3","Hiragino Mincho Pro", "ＭＳ 明朝", serif;
  font-size: 40px;
  font-weight: normal;
  line-height: 180px;
  letter-spacing: 0.2em;
  color: #fff;
  #MainImgInner {
    width: 1000px;
  }

}
  /*　メイン
  -------------------------------------*/
#Main {
  min-height: 500px;
  .contBox {
    h3{
      margin-bottom: 80px;
      font-size: 32px;
      line-height: 32px;
      text-align: center;
      color: #5a8ad2;
      letter-spacing: 0.2em;
      span {
        font-size: 16px;
      }
    }
    h4{
      margin-bottom: 25px;
      padding-bottom: 14px;
      border-bottom:#5a8ad2 solid 1px;
      font-size: 22px;
      line-height: 22px;
      color: #5a8ad2;
      span{
        padding-left: 15px;
        font-size: 12px;
        line-height: 22px;
        vertical-align: middle;
      }
    }
    table{
      margin-bottom: 35px;
      width: 1000px;
      border: #dddddd solid 1px;
    }
  }
      p.linkBtn{
      a{
       display: block;
       height: 60px;
       border: #5a8ad2 solid 1px;
       font-size: 20px;
       text-decoration: none;
       line-height: 60px;
       color: #5a8ad2;
       &:hover{
        opacity: 0.7;
       }
      }
    }

}
  /*####################　フッタ　####################*/
  #Footer{
    position: relative;
    background-color: #fbfbfb;
    #ContactBox {
      margin-bottom: 57px;
      padding-top: 50px;
      height: 243px;
      background-color: #eaedf1;
      .innerBasic {
        font-size: 0;
      }
      dl{
        float: left;
        width: 500px;
        height: 143px;
        &:first-child {
          border-right:#d3d8dd solid 1px;
        }
        dt{
          margin-bottom: 15px;
          padding-top: 7px;
          text-align: center;
          font-size: 18px;
          font-weight: bold;
          line-height: 1.3;
          letter-spacing: 0.2em;
          color: #072863;
          span {
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.05em;
          }
        }
        dd{
          text-align: center;
          font-size: 14px;
          line-height: 1.75;
        }
      }
    }
    #footerLogo {
      position: absolute;
      width: 345px;
      height: 40px;
      bottom:113px;
      left: 0;
      a{
        display: block;
        width: 345px;
        height: 40px;
        background:url(../img/footer/logo_footer.png) 0 0 no-repeat;
        text-indent: -9999px;

      }
    }
    .pageTop {
      display: none;
      position: fixed;
      right: 1px;
      bottom:16px;
      z-index: 60;
      a {
        display: block;
        width: 30px;
        height: 18px;
        background: url(../img/footer/arrow_01.png) center center no-repeat;
        text-indent: -9999px;
      }
      &:hover {
        opacity: 0.7;
      }
    }
    #FooterNav {
      margin-bottom: 53px;
      ul{
        margin-bottom: 15px;
        text-align: right;
        font-size: 0;
        li {
          display: inline-block;
          margin-left: 30px;
          font-size: 14px;
          a{
            text-decoration: none;
            &:hover{
              opacity: 0.7;
            }
          }
        }
      }
    }
    #CopyRight {
      height: 50px;
      border-top: #eaeaea solid 1px;
      font-size: 10px;
      color: #a6a6a6;
      h2 {
        display: none;
      }
      small,address{
        display: inline-block;
        line-height: 50px;
      }
    }
  }


@media print,
screen and (min-width:1001px) and ( max-width:1040px) {
  #GlobalNav{
    padding-left: calc((100% - 1000px) / 2) ;
    padding-right: calc((100% - 1000px) / 2) ;
 }
  .innerBox{
      padding-left: calc((100% - 1000px) / 2) ;
      padding-right: calc((100% - 1000px) / 2) ;
  }
  #TopicPath{
    margin-left:calc((100% - 1000px) / 2) ;
  }
  #Footer{
    .innerBox{
      #footerLogo{
        left: calc((100% - 1000px) / 2) ;
      }
    }
    .pageTop{
      right: calc((100% - 1000px) / 2) ;
    }
    #CopyRight{
      small{
      margin-left: calc((100% - 1000px) / 2) ;
    }
    }
  }
}


@media print,
screen and (min-width: 1041px) {
  #GlobalNav{
    padding-left: 20px;
    padding-right: 20px;
  }
  .innerBox{
      padding-left: 20px;
      padding-right: 20px;
  }
  #TopicPath{
    margin-left:20px;
  }
  #Footer{
    .innerBox{
      #footerLogo{
        left: 20px;
      }
    }
    .pageTop{
      right: 20px;
    }
    #CopyRight{
      small{
      margin-left: 20px;
    }
    }
  }
}



/*end*/